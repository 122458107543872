<template>

  <div class="col-lg-7 col-md-7">

    <iq-card style="background: #f8f9fa;">
      <div class="iq-card-body profile-page p-0">
        <div class="profile-header">
          <div class="cover-container">
            <!-- <img :src="user.profile_banner" alt="profile-bg" class="rounded img-fluid" /> -->
            <div class="w-100" :style="`height: 200px;background-size: cover;background-position: center center;background-image: url(${user.profile_banner});`">
            </div>
            <ul class="header-nav d-flex flex-wrap justify-end p-0 m-0">
              <li v-if="user_.user_type === 2" @click="$router.push({ name: 'user.profile-edit' })">
                <a href="javascript:void(0);">
                  <i class="ri-pencil-line"></i>
                </a>
              </li>
              <li v-if="user_.user_type === 2" @click="$router.push({ name: 'user.other-setting' })">
                <a href="javascript:void(0);"><i class="ri-settings-4-line"></i></a>
              </li>
            </ul>
          </div>

          <div class="d-flex container_wrap" style="justify-content: space-between; padding: 0 20px;">
            <div class="user-detail text-center d-flex pt-1 pb-1 align-items-center">
              <div class="profile-img" v-viewer>
                <img :src="user.avatar" alt="profile-img" class="user-avatar-130x130 img-fluid" style="margin-right: 5px;border-radius: 50%; height: 160px; border: 5px solid rgb(255, 255, 255);" />
              </div>
              <div class="profile-detail mt-4">
                <h3 class="">{{user.user_name}}</h3>
                <span>{{user.account}}</span>
              </div>
            </div>
            <div id="profile1" class="profile-info pt-2 position-relative">
              <div class="social-info">
                <ul class="social-data-block d-flex align-items-center list-inline p-0 m-0">
                  <li class="text-center ps-3">
                    <h6>{{ $t('貼文') }}</h6>
                    <p class="mb-0">{{user.post_total}}</p>
                  </li>
                  <li class="text-center ps-3">
                    <h6>{{ $t('粉絲') }}</h6>
                    <p class="mb-0">{{user.fans_total}}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div id="profile2" class="profile-info p-4 d-flex align-items-center justify-content-between position-relative">
            <div class="social-links">
              <ul class="social-data-block d-flex align-items-center justify-content-between list-inline p-0 m-0">
                <li v-if="user.facebook" class="text-center pe-3">
                  <a :href="user.facebook" target="_blank">
                    <el-image style="width: 25px; height: 25px" :src="require('@/assets/images/social/fb.svg')"></el-image>
                  </a>
                </li>
                <li v-if="user.ig" class="text-center pe-3">
                  <a :href="user.ig" target="_blank">
                    <el-image style="width: 25px; height: 25px" :src="require('@/assets/images/social/ig.svg')"></el-image>
                  </a>
                </li>
                <li v-if="user.twitter" class="text-center pe-3">
                  <a :href="user.twitter" target="_blank">
                    <el-image style="width: 25px; height: 25px" :src="require('@/assets/images/social/twitter.svg')"></el-image>
                  </a>
                </li>
                <li v-if="user.tik_tok" class="text-center pe-3">
                  <a :href="user.tik_tok" target="_blank">
                    <el-image style="width: 25px; height: 25px" :src="require('@/assets/images/social/tiktok.svg')"></el-image>
                  </a>
                </li>
                <li v-if="user.yt" class="text-center pe-3">
                  <a :href="user.yt" target="_blank">
                    <el-image style="width: 25px; height: 25px" :src="require('@/assets/images/social/youtube.svg')"></el-image>
                  </a>
                </li>
              </ul>
            </div>

          </div>
          <div v-if="user.personal_profile" class="p-4 pt-2 pb-2">
            <h4 class="card-title">{{$t('label.personalProfile')}}</h4>
            <p v-html="user.personal_profile.replaceAll('\n', '<br>')"></p>
          </div>
        </div>
      </div>
    </iq-card>

    <!-- {{user}} -->
    <div class="col-sm-12">
      <div class="tab-content">
        <tab-content-item :active="true" id="profile-feed" aria-labelled-by="pills-feed-tab">
          <div class="iq-card-body p-0">
            <div class="row">
              <div class="col-sm-12">
                <tab-content id="pills-tabContent-2">
                  <tab-content-item :active="true" id="profile-feed" aria-labelled-by="pills-feed-tab">
                    <AddSocialPost @addPost="addPost" :settions="settions"></AddSocialPost>
                  </tab-content-item>
                </tab-content>
              </div>
              <div class="col-lg-12" style="position: relative;">
                <!-- <div id="post-modal-data" class="iq-card" v-infinite-scroll="getRows" :infinite-scroll-disabled="commomData.noResult">
                  <SocialPost :post="list" :settions="settions" :showSettions="true" :previewUser="user" />
                </div> -->
                <div
                  v-if="user.user_type === 2"
                  class="cart_icon d-flex align-items-center"
                  @click="$router.push(`/auth/home?user_account=${user.user_account}`)"
                >
                  <i class="ri-store-line me-1" style="display: block; font-size: 22px;"></i>
                  <div>商城</div>
                </div>
                <EditPost :isCalendar="false"></EditPost>
                <el-tabs v-model="activeName" type="card">
                  <el-tab-pane :label="$t('label.common_post')" name="1">
                    <div id="post-modal-data" class="iq-card" v-infinite-scroll="getRows" :infinite-scroll-disabled="commomData.noResult">
                      <AddSocialPost @addPost="addPost" :settions="settions"></AddSocialPost>
                      <SocialPost :post="list" :settions="settions" :showSettions="currentWidth > 768 ? false : true" :previewUser="user" />
                    </div>
                  </el-tab-pane>
                  <el-tab-pane :label="$t('label.single_unlock')" name="2">
                    <div id="post-modal-data" class="iq-card" v-infinite-scroll="getSingleInlockRows" :infinite-scroll-disabled="singleData.noResult">
                      <AddSocialPost @addPost="addPost" :settions="settions"></AddSocialPost>
                      <SocialPost :post="singleUnlockList" :settions="settions" :showSettions="currentWidth > 768 ? false : true" :previewUser="user" :singleLockNew="true" />
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </div>
          </div>
        </tab-content-item>
      </div>
    </div>

    <div v-if="!commomData.noResult" class="col-sm-12 text-center">
      <img src="../../../assets/images/page-img/page-load-loader.gif" alt="loader" style="height: 100px" />
    </div>
  </div>

 

  <div v-if="currentWidth > 768" class="col-lg-3 col-md-3">
    <!-- 促銷 -->
    <div v-if="settions.promotions?.length > 0" class="card card-block card-stretch card-height" style="height: auto;">
        <div class="card-header d-flex justify-content-between">
          <div class="header-title">
            <h5 class="card-title">{{ $t('label.promotion') }}</h5>
          </div>
        </div>
        <div v-for="(item, index) in settions.promotions" :key="index" class="card-body">
          <div class="d-grid">
            <button :disabled="onLoading" @click="
                openDialog(
                  item,
                  5,
                  `${$t('促銷')}`,
                  `${$t('訂閱')} / ${$t('促銷')} ${item.promotions_month} ${$t('個月')} (${$t('省下')} ${item.promotions_discount})`,
                  item.promotions_amount,
                  // previewUser,
                  user,
                  'single'
                )
              " type="button" class="btn btn-primary rounded-pill">
              <div class="d-flex justify-content-between">
                <span>{{ $t('訂閱') }} / {{ $t('促銷') }} {{ item.promotions_month }} {{ $t('個月') }} ({{ $t('省下') }}{{
                    item.promotions_discount
                  }})</span>
                <span>{{ item.promotions_amount }}</span>
              </div>
            </button>
            <div class="text-secondary">{{ item.program_desc }}</div>
          </div>
        </div>
      </div>
      <!-- 綑綁 -->
      <div v-if="settions.subscription_bundle?.length > 0" class="card card-block card-stretch card-height" style="height: auto;">
        <div class="card-header d-flex justify-content-between">
          <div class="header-title">
            <h5 class="card-title">{{ $t('label.subscriptionBundle') }}</h5>
          </div>
        </div>
        <div v-for="(item, index) in settions.subscription_bundle" :key="index" class="card-body">
          <div class="d-grid">
            <button :disabled="onLoading" @click="
                openDialog(
                  item,
                  6,
                  `${$t('訂閱綑綁')}`,
                  `${$t('訂閱')} / ${$t('促銷')} ${item.subscription_bundle_duration} ${$t('個月')} (${$t('省下')} ${item.subscription_bundle_discount})`,
                  item.subscription_bundle_amount,
                  // previewUser,
                  // (options = 'single')
                  user,
                  'single'
                )
              " type="button" class="btn btn-primary rounded-pill">
              <div class="d-flex justify-content-between">
                <span>{{ $t('訂閱') }} / {{ item.subscription_bundle_duration }} {{ $t('個月') }} ({{ $t('省下') }}{{
                    item.subscription_bundle_discount
                  }})</span>
                <span> {{ item.subscription_bundle_amount }}</span>
              </div>
            </button>
            <div class="text-secondary">{{ item.program_desc }}</div>
          </div>
        </div>
      </div>
      <!-- 方案 -->
      <div v-if="settions.program?.length > 0" class="card card-block card-stretch card-height" style="height: auto;">
        <div class="card-header d-flex justify-content-between">
          <div class="header-title">
            <h5 class="card-title">{{ $t('label.subscription') }}</h5>
          </div>
        </div>
        <div v-for="(item, index) in settions.program" :key="index" class="card-body">
          <div class="d-grid">
            <button :disabled="onLoading || item.is_btn_enabled === 0" @click="
                openDialog(
                  item,
                  4,
                  `${$t('訂閱')}`,
                  `${$t('訂閱')} / ${$t('每月')}．${item.program_title}`,
                  item.program_price,
                  // previewUser,
                  // (options = 'single')
                  user,
                  'single'
                )
              " type="button" class="btn btn-primary rounded-pill subscribe_btn">
              <div class="d-flex justify-content-between">
                <span> {{ $t('訂閱') }} / {{ $t('每月') }}．{{ item.program_title }}</span>
                <span v-if="item.is_btn_enabled === 1">{{ item.program_price }}</span>
                <span v-else>將於 {{ item.exp_datetime }} 到期</span>
              </div>
            </button>
            <!-- <div class="text-secondary">{{ item.program_desc }}</div> -->
          </div>
        </div>
      </div>
  </div>

</template>
<script>
// import { socialvue } from '../../../config/pluginInit'
// import ProfileImage from './ProfileImage'
// import FriendTab from './ProfileFriends/FriendTab'
// import About from './ProfileFriends/About'
import AddSocialPost from '../../Apps/Social/Components/AddSocialPost'
import EditPost from '../../Apps/Social/Components/EditPost'
import Post from '../../../Model/Post'
import { mapGetters } from 'vuex'
import { getSettings } from '@/api/settings'
import { ref, onMounted, getCurrentInstance } from 'vue'
import { getOtherProfile } from '@/api/user'

import { getPost } from '@/api/post'
import { commomData } from '@/utils/commonVmodel'
import SocialPost from '../../Apps/Social/Components/SocialPost'
import { useRoute } from 'vue-router'
import { useCheckout } from '@/utils/useCheckout'

export default {
  name: 'CreatorProfile',
  setup() {
    const { proxy } = getCurrentInstance()
    const route = useRoute()
    const $toast = proxy.$toast
    const userAccount = route.params.user_account // read parameter id (it is reactive)
    const settions = ref({})
    const d = {
      user_account: userAccount
    }
    const getUserSettions = () => {
      getSettings(d)
        .then(res => {
          settions.value = res
        })
        .catch(err => {
          console.log(err)
        })
    }
    onMounted(() => {
      getUserSettions()
    })
    const {
      openTipDialog,
      openDialog,
      checkoutForm,
      action,
      onLoading
    } = useCheckout()
    return {
      settions,
      $toast,
      openTipDialog,
      openDialog,
      checkoutForm,
      action,
      onLoading
    }
  },
  computed: {
    ...mapGetters({
      user_: 'User/userState',
      token: 'User/tokenState',
      isPlan: 'User/isPlanState'
    })
  },
  mounted() {
    const query = {
      user_account: this.$route.params.user_account
    }
    getOtherProfile(query)
      .then(res => {
        console.log(res)
        this.user = res.user
        this.getRows()
      })
      .catch(err => {
        console.log(err)
        this.$toast.show(err.response.data.message)
        setTimeout(() => {
          this.$router.push({ name: 'social.list' })
        }, 2000)
      })

    
    const self = this;
    self.currentWidth = window.innerWidth;
    window.onresize = () => {
      self.currentWidth = window.innerWidth;
    };
  },
  created() {},
  components: {
    AddSocialPost,
    SocialPost,
    EditPost
  },
  data() {
    return {
      user: {},
      list: [],
      singleUnlockList: [],
      singleUnlockpage: 1,
      primaryKey: 'created_at',
      commomData: commomData(),
      post: new Post(),
      activeName: '1',
      singleData: {
        condition: {
          sort_by: 'desc',
          order_by: '',
          page_size: 10,
          page: 1
        },
        noResult: false,
      },
      currentWidth: ''
      // social: [
      //   require('../../../assets/images/icon/08.png'),
      //   require('../../../assets/images/icon/09.png'),
      //   require('../../../assets/images/icon/10.png'),
      //   require('../../../assets/images/icon/11.png'),
      //   require('../../../assets/images/icon/12.png'),
      //   require('../../../assets/images/icon/13.png')
      // ]
    }
  },
  methods: {
    async getRows() {
      if (this.commomData.noResult) {
        return false
      }
      this.commomData.listLoader = true
      this.commomData.condition.order_by = this.primaryKey
      this.commomData.condition.post_type = 7
      this.commomData.condition.user_account = this.$route.params.user_account
      await getPost(this.commomData.condition)
        .then(res => {
          if (this.commomData.condition.page === 1) {
            this.list = res.data
            this.commomData.condition.page = 2
            this.commomData.listLoader = false
            if (res.total === 0) {
              this.commomData.noResult = true
            }
          } else if (res.total > this.list.length) {
            this.list.push(...res.data)
            this.commomData.condition.page = this.commomData.condition.page + 1
            this.commomData.listLoader = false
          } else if (res.total === this.list.length) {
            this.commomData.noResult = true
            this.commomData.listLoader = false
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    async getSingleInlockRows() {
      if (this.singleData.noResult) {
        return false
      }
      // this.commomData.listLoader = true
      this.singleData.condition.order_by = this.primaryKey
      this.singleData.condition.post_type = 8
      // this.singleData.condition.page_size = 2
      this.singleData.condition.user_account = this.user.account
      this.singleData.condition.user_account = this.$route.params.user_account
      await getPost(this.singleData.condition)
        .then(res => {
          if (this.singleUnlockpage === 1) {
            this.singleUnlockList = res.data
            this.singleUnlockpage = 2
            this.singleData.condition.page = 2
            // this.commomData.listLoader = false
            if (res.total === 0) {
              this.singleData.noResult = true
            }
          } else if (res.total > this.singleUnlockList.length) {
            this.singleUnlockList.push(...res.data)
            this.singleUnlockpage = this.singleUnlockpage + 1
            this.singleData.condition.page = this.singleData.condition.page + 1
            // this.commomData.listLoader = false
          } else if (res.total === this.singleUnlockList.length) {
            this.singleData.noResult = true
            // this.commomData.listLoader = false
          }
        })
        .catch(err => {
          console.log(err)
          // this.$swal.mixin().fire({
          //   icon: 'error',
          //   title: 'err.response.data.message'
          // })
        })
    }
  }
}
</script>

<style scoped>
.card-title {
  margin-bottom: 2px !important;
}
@media screen and (max-width: 600px) {
  .container_wrap {
    display: block !important;
  }
  #profile1 {
    padding-top: 0 !important;
    margin-top: -20px !important;
  }
  #profile2 {
    padding-top: 10px !important;
  }
}

@media screen and (min-width: 600px) {
  .container_wrap,
  .p-40 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
}

.cart_icon {
  position: absolute;
  top: 0;
  right: 15px;
  z-index: 999;
  cursor: pointer;
  /* width: 45px;
  height: 45px; */
}
</style>
